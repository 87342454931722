.specs {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-self: flex-start;
    padding: 20px;
}

.specs-title {
    font-size: 15pt;
    text-align: center;
    padding: 10px;
}

.specs-table {
    min-width: 170px;
}

.specs-download {
    font-weight: 500;
    text-align: start;
}