.page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
    background-color: whitesmoke;
    justify-content: flex-start;
    align-self: flex-start;
    min-height: 100%;
    margin-left: 220px;
}

.title {
    justify-self: flex-start;
    align-self: flex-start;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.5rem;
    color: black;
    margin: 15px;
}