.App {
  display: flex;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* SIDEBAR */
.sidebar {
  display: flex;
  flex-direction: column;
  width: 200px;
  border-right: 1px solid #d7dbdd;
  padding: 10px;
  position: fixed;
  height: 100%;
}

.logo {
  padding-bottom: 10px;
  border-bottom: 1px solid #d7dbdd;
  width: 125px;
  margin: 5px 5px 5px 15px;
}

/* SIDEBAR ITEM */
.selected {
  /* width: 240px; */
  background-color: lightgrey;
}

.item > a{
  text-decoration: none;
  cursor: pointer;
}

.item:hover {
  background-color: rgba(25, 118, 210, 0.08);
}

.button {
  width: 100%;
  pointer-events: none;
}

.buttonText {
  display: flex;
  flex-grow: 1;
}

.buttonSubItemText {
  text-transform: none !important;
  padding-left: 20px !important;
}
